import { useFormPage } from '@/lib/composables/form/useFormPage';
import { ConfiguracaoFinanceiraService } from '@/lib/services';
import { createComponent } from '@/lib/vue';
import { IConfiguracaoFinanceiraModel } from '@/typings';
import { ConfiguracaoClinicaPage } from '../../components/ConfiguracaoClinicaPage';
import { ConfiguracaoFinanceiraForm } from '../components/ConfiguracaoFinanceiraForm';

export default createComponent({
  name: 'ConfiguracoesFinanceirasPage',
  setup(props, ctx) {
    const { page, handleSubmit } = useFormPage<IConfiguracaoFinanceiraModel>({
      ctx,
      submitCallback(model) {
        return ConfiguracaoFinanceiraService.update(model);
      },
    });

    return () => (
      <ConfiguracaoClinicaPage title="Configurações Financeiras" form>
        <ConfiguracaoFinanceiraForm page={page} onSubmit={handleSubmit} />
      </ConfiguracaoClinicaPage>
    );
  },
});
