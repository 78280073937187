import { ContextSidebar } from '@/components/sidebars/context/ContextSidebar';
import { ContextSidebarMenu } from '@/components/sidebars/context/ContextSidebarMenu';
import { MyIcons } from '@/lib/helpers/MyIcons';
import { createComponent } from '@/lib/vue';
import { Routes } from '@/routes/routes';
import { IMenu } from '@/typings';

export default createComponent({
  name: 'ConfiguracaoClinicaSidebar',
  setup(props, ctx) {
    const menus: IMenu[] = [
      {
        text: 'Configurações da clínica',
        header: true,
      },
      {
        text: 'Perfil da clínica',
        icon: MyIcons.clinica,
        to: Routes.app.configClinica.perfilClinica,
      },
      {
        text: 'Convênios',
        icon: MyIcons.convenio,
        to: Routes.app.configClinica.convenios.index,
      },
      {
        text: 'Finanças',
        subheader: true,
      },
      {
        text: 'Categorias financeiras',
        icon: MyIcons.categoriaFinanceira,
        to: Routes.app.configClinica.financas.categoriasFinanceiras.index,
      },
      {
        text: 'Centro de custo',
        icon: MyIcons.centroCusto,
        to: Routes.app.configClinica.financas.centrosCusto.index,
      },
      {
        text: 'Configurações financeiras',
        icon: MyIcons.configuracaoFinanceira,
        to: Routes.app.configClinica.financas.configuracoesFinanceiras,
      },
      {
        text: 'Contas financeiras',
        icon: MyIcons.contaFinanceira,
        to: Routes.app.configClinica.financas.contasFinanceiras.index,
      },
      {
        text: 'Geral',
        subheader: true,
      },
      {
        text: 'Equipe',
        icon: MyIcons.users,
        to: Routes.app.configClinica.equipe,
      },
      {
        text: 'Regras de repasse',
        icon: MyIcons.regrasRepasse,
        to: Routes.app.configClinica.regrasRepasse,
      },
      {
        text: 'Exportar dados',
        icon: MyIcons.exportar,
        to: Routes.app.configClinica.exportarDados,
      },
    ];

    return () => (
      <ContextSidebar>
        <ContextSidebarMenu menus={menus} />
      </ContextSidebar>
    );
  },
});
