import { FormFields } from '@/components/form/fields/FormFields';
import { MyForm } from '@/components/form/MyForm';
import { PageSection } from '@/components/page/PageSection';
import { useValue } from '@/lib/composables';
import {
  IFormEvents,
  IFormProps,
  useFormConfig,
} from '@/lib/composables/form/useFormConfig';
import { INomeEvents, useNome } from '@/lib/composables/utils/useNome';
import { ConstantsHelper } from '@/lib/constants/helper';
import {
  ConfiguracaoFinanceiraService,
  ContaFinanceiraService,
} from '@/lib/services';
import { createComponent } from '@/lib/vue';
import { Routes } from '@/routes/routes';
import { redirectError } from '@/routes/utils';
import {
  ContaFinanceiraTipo,
  IContaFinanceiraModel,
  IFormPage,
  IFormSchema,
} from '@/typings';
import { computed, SetupContext } from '@vue/composition-api';

interface IProps extends IFormProps {}

interface IEvents extends IFormEvents<IContaFinanceiraModel>, INomeEvents {}

export const ContaFinanceiraForm = createComponent<IProps, IEvents>({
  name: 'ContaFinanceiraForm',
  props: {
    page: { type: Object, required: true },
  },
  setup(props, ctx) {
    const indexRoute =
      Routes.app.configClinica.financas.contasFinanceiras.index;

    const { $form, $schema, emitSubmit, emitDelete } = useForm(props, ctx);

    useNome({
      ctx,
      watchNome: () => $form.value.model.nome,
    });

    const { $noDelete } = useComputeds(props.page);

    return () => (
      <MyForm
        form={$form.value}
        cancelTo={indexRoute}
        noDelete={$noDelete.value}
        onSubmit={emitSubmit}
        onDelete={emitDelete}
      >
        <PageSection>
          <FormFields
            form={$form.value}
            schema={$schema.value}
            v-model={$form.value.model}
          />
        </PageSection>
      </MyForm>
    );
  },
});

function useForm(props: IProps, ctx: SetupContext) {
  return useFormConfig<
    IContaFinanceiraModel,
    IFormSchema<IContaFinanceiraModel>
  >({
    page: props.page,
    initialValue: {
      nome: null,
      saldoInicial: null,
      dataSaldoInicial: null,
      banco: null,
      tipo: null,
    },
    ctx,
    mapSchema: model => {
      const isCaixaInterno = model.tipo === ContaFinanceiraTipo.CAIXA_INTERNO;

      return {
        nome: {
          label: 'Nome',
          type: 'text',
          validations: {
            required: true,
          },
        },
        saldoInicial: {
          label: 'Saldo inicial',
          type: 'money',
          layout: {
            maxWidth: 260,
          },
          validations: {
            required: true,
          },
        },
        dataSaldoInicial: {
          label: 'Data do saldo',
          type: 'date',
          layout: { maxWidth: 254 },
          validations: { required: true },
        },
        tipo: {
          label: 'Tipo da conta',
          type: 'select',
          items: ConstantsHelper.contaFinanceiraTipos,
          validations: {
            required: true,
          },
        },
        banco: {
          label: 'Banco',
          type: 'autocomplete',
          items: ConstantsHelper.bancos,
          hidden: !model.tipo || isCaixaInterno,
          layout: {
            sm: 6,
          },
          validations: {
            required: [
              ContaFinanceiraTipo.CONTA_CORRENTE,
              ContaFinanceiraTipo.CONTA_POUPANCA,
              ContaFinanceiraTipo.INVESTIMENTO,
            ].includes(model.tipo!),
          },
        },
      };
    },
    async loadEditCallback({ id, setFormModel }) {
      const editValue = await ContaFinanceiraService.getById(id);

      if (!editValue) return redirectError(404);

      setFormModel({
        nome: editValue.nome,
        saldoInicial: editValue.saldoInicial,
        dataSaldoInicial: editValue.dataSaldoInicial,
        banco: editValue.banco,
        tipo: editValue.tipo,
      });
    },
  });
}

function useComputeds(page: IFormPage) {
  const [$contaPadraoId, setContaPadraoId] = useValue<string | null>(null);

  async function loadConfiguracao() {
    const configuracao = await ConfiguracaoFinanceiraService.get();
    if (configuracao) {
      setContaPadraoId(configuracao.contaFinanceira.id);
    }
  }

  const $noDelete = computed(
    () => !$contaPadraoId.value || page.id === $contaPadraoId.value,
  );

  loadConfiguracao();

  return {
    $contaPadraoId,
    $noDelete,
  };
}
