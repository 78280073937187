import { useValue } from '@/lib/composables';
import { MyIcons } from '@/lib/helpers/MyIcons';
import { createComponent, watchRun } from '@/lib/vue';
import { IMenuSelected, ISelectedMenusActions, ITreeviewItem } from '@/typings';
import { computed, ComputedRef } from '@vue/composition-api';
import { RawLocation } from 'vue-router';
import { BtnSelectedRows } from '../buttons/BtnSelectedRows';
import { SearchRow } from '../datatable/SearchRow';
import { CircularLoading } from '../loading/CircularLoading';

interface IProps {
  items: ITreeviewItem[];
  loading?: boolean;
  editToFn?: (id: string) => string;
  newChildToFn?: (id: string) => string | RawLocation;
  newChildToTooltip?: string | null;
  actions?: ISelectedMenusActions | null;
}

export const Treeview = createComponent<IProps>({
  name: 'Treeview',
  props: {
    items: { type: Array, required: true },
    loading: { type: Boolean, default: false },
    editToFn: { type: Function },
    newChildToFn: { type: Function },
    newChildToTooltip: { type: String },
    actions: { type: Object },
  },
  setup(props, ctx) {
    const { $search, $items, $open } = useData(props);

    const { $selected, $selectedMenus } = useSelected({ props, $items });

    return () => (
      <div id="Treeview" class="flex">
        <CircularLoading loading={!!props.loading}>
          <div class="flex flex-col">
            <SearchRow v-model={$search.value} class="m-4">
              <BtnSelectedRows
                selected={$selected.value}
                menus={$selectedMenus.value}
              />
            </SearchRow>

            <v-treeview
              v-model={$selected.value}
              hoverable
              openOnClick
              transition
              selectable
              items={$items.value}
              open={$open.value}
              search={$search.value}
              class="mt-2 mr-4"
              scopedSlots={{
                append: actionsRow(props),
              }}
            />
          </div>
        </CircularLoading>
      </div>
    );
  },
});

export default Treeview;

function useData(props: IProps) {
  const [$search] = useValue('');

  const $open = computed(() => props.items.map(v => v.id));

  const $items = computed(() =>
    props.items.map(v => {
      if (v.children) {
        v.children.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          } else if (a.name > b.name) {
            return 1;
          }

          return 0;
        });
      }

      return v;
    }),
  );

  return { $search, $open, $items };
}

function useSelected({
  props,
  $items,
}: {
  props: IProps;
  $items: ComputedRef<ITreeviewItem[]>;
}) {
  const [$selected, setSelected] = useValue<ITreeviewItem[]>([]);

  watchRun($items, () => setSelected([]));

  const $selectedMenus = computed<IMenuSelected[]>(() => {
    let menus: IMenuSelected[] = [];

    const selecionado =
      $selected.value.length > 1 ? 'selecionados' : 'selecionado';

    if (props.actions) {
      if (props.actions.removeMany) {
        menus.push({
          text: `Excluir ${selecionado}`,
          icon: MyIcons.remove,
          action: props.actions.removeMany,
        });
      }

      if (props.actions.selectedMenus) {
        menus = [...menus, ...props.actions.selectedMenus];
      }
    }

    return menus;
  });

  return { $selected, $selectedMenus };
}

const actionsRow =
  (props: IProps) =>
  ({ item }: { item: ITreeviewItem }) =>
    (
      <div class="flex actions">
        <v-spacer />

        {props.newChildToFn && item.children && (
          <v-btn
            aria-label={props.newChildToTooltip}
            data-balloon-pos="left"
            class="mx-1 my-0"
            to={props.newChildToFn(item.id)}
            text
            icon
          >
            <v-icon>{MyIcons.fileTree}</v-icon>
          </v-btn>
        )}

        {props.editToFn && (
          <v-btn
            aria-label="Editar"
            data-balloon-pos="left"
            class="mx-1 my-0"
            to={props.editToFn(item.id)}
            text
            icon
          >
            <v-icon>{MyIcons.edit}</v-icon>
          </v-btn>
        )}
      </div>
    );
