import { FormFields } from '@/components/form/fields/FormFields';
import { MyForm } from '@/components/form/MyForm';
import { PageSection } from '@/components/page/PageSection';
import {
  IDialogFormProps,
  IFormEvents,
  useDialogFormConfig,
} from '@/lib/composables/form/useFormConfig';
import { createComponent } from '@/lib/vue';
import { IConvenioPlanoModel, IFormSchema } from '@/typings';
import { SetupContext } from '@vue/composition-api';

interface IProps extends IDialogFormProps<IConvenioPlanoModel> {}

interface IEvents extends IFormEvents<IConvenioPlanoModel> {}

export const ConvenioPlanoForm = createComponent<IProps, IEvents>({
  name: 'ConvenioPlanoForm',
  props: {
    page: { type: Object, required: true },
    initialValue: { type: Object },
  },
  setup(props, ctx) {
    const { $form, $schema, emitSubmit, emitCancel } = useForm(props, ctx);

    return () => (
      <MyForm
        dialog
        form={$form.value}
        onSubmit={emitSubmit}
        onCancel={emitCancel}
      >
        <PageSection>
          <FormFields
            form={$form.value}
            schema={$schema.value}
            v-model={$form.value.model}
          />
        </PageSection>
      </MyForm>
    );
  },
});
function useForm(props: IProps, ctx: SetupContext) {
  const defaultValue: IConvenioPlanoModel = { nome: null };

  return useDialogFormConfig<
    IConvenioPlanoModel,
    IFormSchema<IConvenioPlanoModel>
  >({
    page: props.page,
    ctx,
    initialValue: props.initialValue || defaultValue,
    mapSchema: () => ({
      nome: {
        label: 'Nome',
        type: 'text',
        autofocus: true,
        validations: {
          required: true,
        },
      },
    }),
  });
}
