import { DataTable } from '@/components/datatable/DataTable';
import { PageSection } from '@/components/page/PageSection';
import { FormHeader } from '@/components/typography/FormHeader';
import { QueryGraphql } from '@/graphql/query';
import { useAuthInfo } from '@/lib/composables/useAuthInfo';
import { useQueryConfig } from '@/lib/composables/useQueryConfig';
import {
  getEquipeTipoAcesso,
  getEquipeTipoAcessoLabel,
  getUserClinica,
  getUserEspecialidade,
  getUserHasOneClinica,
} from '@/lib/helpers/models/users';
import { createComponent, watchRun } from '@/lib/vue';
import {
  IEquipeProfissionaisItem,
  IEquipeUserModel,
  IRequireField,
  IUsersQuery,
  IUsersQueryVariables,
  IDataTableHeader,
  UserTipo,
} from '@/typings';
import { SetupContext } from '@vue/composition-api';
import {
  EquipeProfissionaisTdMembro,
  EquipeProfissionaisTdTipoAcesso,
} from './equipeProfissionais/equipeProfissionaisSlots';

interface IJsxProps {
  // model
  value?: IEquipeUserModel[];
  recepcionista?: boolean;
}

type IProps = IRequireField<IJsxProps, 'value'>;

interface IEvents {
  onInput: (model: IEquipeUserModel[]) => void;
}

export const EquipeProfissionais = createComponent<IJsxProps, IEvents>({
  name: 'EquipeProfissionais',
  props: {
    value: { type: Array, required: true },
    recepcionista: { type: Boolean, default: false },
  },
  setup(props: IProps, ctx) {
    const { headers, title } = useData(props);

    const { $items, $loading } = useEvents(props, ctx);

    return () => (
      <PageSection divider={props.recepcionista}>
        <FormHeader title={title} />

        <DataTable
          headers={headers}
          items={$items.value}
          loading={$loading.value}
        />
      </PageSection>
    );
  },
});

function useData(props: IProps) {
  const title = props.recepcionista
    ? 'Recepcionistas'
    : 'Profissionais de saúde';

  const headers: IDataTableHeader<IEquipeProfissionaisItem>[] = [
    {
      text: 'Membro',
      value: 'membro',
      slot: ({ header, item }) => (
        <EquipeProfissionaisTdMembro header={header} item={item} />
      ),
      align: 'center',
      sortable: false,
      width: 80,
    },
    {
      text: 'Nome',
      value: 'nome',
      mapValue: v => v.nome,
    },
    {
      text: 'Especialidade',
      value: 'especialidade',
      mapValue: v => v.especialidade,
      // only profissional
      hide: props.recepcionista,
    },
    {
      text: 'Tipo de acesso',
      value: 'tipoAcesso',
      slot: ({ header, item }) => (
        <EquipeProfissionaisTdTipoAcesso header={header} item={item} />
      ),
      sortable: false,
      width: 270,
    },
  ];

  return { title, headers };
}

function useEvents(props: IProps, ctx: SetupContext) {
  function emitInput(value: IEquipeUserModel[]) {
    ctx.emit('input', value);
  }

  const { $data: $items, $loading } = useEquipeQuery(props);

  watchRun(
    $items,
    items => {
      const value = (items || [])
        .filter(f => f.membro)
        .map(({ id, tipoAcesso }) => ({
          userId: id,
          tipoAcesso,
        }));

      emitInput(value);
    },
    { deep: true },
  );

  return { $items, $loading };
}

function useEquipeQuery({ recepcionista }: { recepcionista?: boolean }) {
  const { $clinica } = useAuthInfo();

  return useQueryConfig<
    IUsersQuery,
    IEquipeProfissionaisItem[],
    IUsersQueryVariables
  >({
    query: QueryGraphql.UsersQuery,
    variables: () => ({
      where: {
        tipo: recepcionista
          ? UserTipo.RECEPCIONISTA
          : UserTipo.PROFISSIONAL_SAUDE,
      },
    }),
    mapData(result) {
      const clinicaId = $clinica.value!.id;

      return (
        result?.users.nodes.map(v => {
          return {
            id: v.id,
            membro: !!getUserClinica(v, clinicaId),
            nome: v.nome,
            especialidade: getUserEspecialidade(v),
            tipoAcesso: getEquipeTipoAcesso(v, clinicaId),
            tipoAcessoLabel: getEquipeTipoAcessoLabel(v, clinicaId),
            onlyClinica: getUserHasOneClinica(v, clinicaId),
          };
        }) || []
      );
    },
  });
}
