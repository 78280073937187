import { Td } from '@/components/datatable/columns/Td';
import { TdSelect } from '@/components/datatable/columns/TdSelect';
import { TdSwitch } from '@/components/datatable/columns/TdSwitch';
import { ConstantsHelper } from '@/lib/constants/helper';
import { MyTheme } from '@/lib/helpers/MyTheme';
import { createComponent } from '@/lib/vue';
import { getUser } from '@/store/utils/auth';
import {
  IDataTableHeader,
  IEquipeProfissionaisItem,
  IFormInputSelect,
  IFormInputSwitch,
  UserEquipeTipoAcesso,
} from '@/typings';

interface ITdProps {
  header: IDataTableHeader;
  item: IEquipeProfissionaisItem;
}

export const EquipeProfissionaisTdMembro = createComponent<ITdProps>({
  name: 'EquipeProfissionaisTdMembro',
  props: {
    header: { type: Object, required: true },
    item: { type: Object, required: true },
  },
  setup(props, ctx) {
    return () => (
      <div
        aria-label={membroTooltip(props.item)}
        data-balloon-pos="right"
        data-balloon-length="large"
        class="flex justify-center items-center"
      >
        <TdSwitch
          header={props.header}
          input={getSchema(props.item).membro}
          v-model={props.item.membro}
        />
      </div>
    );
  },
});

export const EquipeProfissionaisTdTipoAcesso = createComponent<ITdProps>({
  name: 'EquipeProfissionaisTdTipoAcesso',
  props: {
    header: { type: Object, required: true },
    item: { type: Object, required: true },
  },
  setup(props, ctx) {
    return () => {
      const isAdminAccount =
        props.item.tipoAcesso === UserEquipeTipoAcesso.ADMIN_ACCOUNT;

      if (isAdminAccount) {
        return (
          <Td header={props.header} item={props.item}>
            <v-chip
              color="accent"
              textcolor={MyTheme.white}
              aria-label="Administradores da conta são considerados administradores da clínica"
              data-balloon-pos="up"
              data-balloon-length="large"
            >
              {props.item.tipoAcessoLabel}
            </v-chip>
          </Td>
        );
      }
      return (
        <TdSelect
          header={props.header}
          input={getSchema(props.item).tipoAcesso}
          v-model={props.item.tipoAcesso}
        />
      );
    };
  },
});

function getSchema(item: IEquipeProfissionaisItem): {
  membro: IFormInputSwitch;
  tipoAcesso: IFormInputSelect;
} {
  return {
    membro: {
      label: null,
      type: 'switch',
      disabled: isMyUser(item) || item.onlyClinica,
    },
    tipoAcesso: {
      label: null,
      type: 'select',
      items: ConstantsHelper.userEquipeTiposAcessos,
      noAttach: true,
      hideDetails: true,
    },
  };
}

function isMyUser(item: IEquipeProfissionaisItem) {
  return item.id === getUser().id;
}

function membroTooltip(item: IEquipeProfissionaisItem) {
  if (isMyUser(item)) {
    return 'Para deixar de ser membro dessa clínica acesse o cadastro do seu usuário';
  } else if (item.onlyClinica) {
    return 'Precisa ser membro de pelo menos uma clínica';
  }

  return null;
}
