import { PageSection } from '@/components/page/PageSection';
import { Treeview } from '@/components/treeview/Treeview';
import { Tabs } from '@/components/utils/Tabs';
import { QueryGraphql } from '@/graphql/query';
import { useValue } from '@/lib/composables';
import { IQueryConfig, useQueryConfig } from '@/lib/composables/useQueryConfig';
import { MyIcons } from '@/lib/helpers/MyIcons';
import { CategoriaFinanceiraService } from '@/lib/services';
import { createComponent } from '@/lib/vue';
import { Routes } from '@/routes/routes';
import {
  CategoriaFinanceiraOrderBy,
  ICategoriasFinanceirasQuery,
  ICategoriasFinanceirasQueryVariables,
  ISelectedMenusActions,
  ITab,
  ITreeviewItem,
  TransacaoTipo,
} from '@/typings';
import { ConfiguracaoClinicaPage } from '../../../components/ConfiguracaoClinicaPage';

export default createComponent({
  name: 'CategoriasFinanceirasPage',
  setup(props, ctx) {
    const { DespesasTab } = useDespesasTab();
    const { ReceitasTab } = useReceitasTab();

    const [$activeTab] = useValue(0);

    const tabs: ITab[] = [
      {
        id: 'despesas',
        text: 'despesas',
        icon: MyIcons.despesa,
      },
      {
        id: 'receitas',
        text: 'receitas',
        icon: MyIcons.receita,
      },
    ];

    return () => (
      <ConfiguracaoClinicaPage title="Categorias financeiras">
        <PageSection>
          <ActionsRow />

          <Tabs items={tabs} v-model={$activeTab.value}>
            <DespesasTab slot="despesas" />

            <ReceitasTab slot="receitas" />
          </Tabs>
        </PageSection>
      </ConfiguracaoClinicaPage>
    );
  },
});

const editRouteFn =
  Routes.app.configClinica.financas.categoriasFinanceiras.edit;

const actions: ISelectedMenusActions = {
  removeMany: CategoriaFinanceiraService.deleteMany,
};

function useDespesasTab() {
  const newChildRouteFn = (parentId: string) =>
    Routes.app.configClinica.financas.categoriasFinanceiras.newChild(
      TransacaoTipo.DESPESA,
      parentId,
    );

  const { $data, $loading } = useQueryConfig(
    getQueryConfig(TransacaoTipo.DESPESA),
  );

  const DespesasTab = () => (
    <Treeview
      items={$data.value || []}
      editToFn={editRouteFn}
      newChildToTooltip="Nova subcategoria"
      newChildToFn={newChildRouteFn}
      loading={$loading.value}
      actions={actions}
    />
  );

  return { DespesasTab };
}

function useReceitasTab() {
  const newChildRouteFn = (parentId: string) =>
    Routes.app.configClinica.financas.categoriasFinanceiras.newChild(
      TransacaoTipo.RECEITA,
      parentId,
    );

  const { $data, $loading } = useQueryConfig(
    getQueryConfig(TransacaoTipo.RECEITA),
  );

  const ReceitasTab = () => (
    <Treeview
      items={$data.value || []}
      editToFn={editRouteFn}
      newChildToTooltip="Nova subcategoria"
      newChildToFn={newChildRouteFn}
      loading={$loading.value}
      actions={actions}
    />
  );

  return { ReceitasTab };
}

function getQueryConfig(
  tipo: TransacaoTipo,
): IQueryConfig<
  ICategoriasFinanceirasQuery,
  ITreeviewItem[],
  ICategoriasFinanceirasQueryVariables
> {
  return {
    query: QueryGraphql.CategoriasFinanceirasQuery,
    variables: () => ({
      where: {
        parentId: null,
        tipo,
      },
      orderBy: [CategoriaFinanceiraOrderBy.nome_ASC],
    }),
    mapData: result =>
      result?.categoriasFinanceiras.nodes.map(v => ({
        id: v.id,
        name: v.nome,
        children: (v.children || []).map(c => ({
          id: c.id,
          name: c.nome,
        })),
      })) || [],
  };
}

const newRoute = Routes.app.configClinica.financas.categoriasFinanceiras.new;

const ActionsRow = () => (
  <div class="flex mb-4">
    <v-spacer />

    <v-btn color="primary" to={newRoute}>
      <v-icon left>{MyIcons.new}</v-icon>
      Nova Categoria
    </v-btn>
  </div>
);
