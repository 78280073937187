import { InputSelect } from '@/components/form/inputs/InputSelect';
import { createComponent } from '@/lib/vue';
import { IDataTableHeader, IFormInputSelect } from '@/typings';
import { PropType } from 'vue';

interface IProps {
  value?: string;
  input: IFormInputSelect;
  header: IDataTableHeader;
}

interface IEvents {
  onInput: (value: string | null) => void;
}

export const TdSelect = createComponent<IProps, IEvents>({
  name: 'TdSelect',
  props: {
    value: String,
    input: { type: Object as PropType<IFormInputSelect>, required: true },
    header: { type: Object as PropType<IDataTableHeader>, required: true },
  },
  setup(props, ctx) {
    function emitInput(value: string | null) {
      ctx.emit('input', value);
    }

    return () => (
      <div
        key={props.header.value}
        style={{ width: `${props.header.width || 200}px` }}
        class="my-2"
      >
        <InputSelect
          input={props.input}
          value={props.value}
          onInput={emitInput}
        />
      </div>
    );
  },
});
