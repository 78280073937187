import { useFormPage } from '@/lib/composables/form/useFormPage';
import { UserService } from '@/lib/services';
import { createComponent } from '@/lib/vue';
import { IEquipeFormModel } from '@/typings';
import { ConfiguracaoClinicaPage } from '../components/ConfiguracaoClinicaPage';
import { EquipeForm } from '../components/equipe/EquipeForm';

export default createComponent({
  name: 'EquipePage',
  setup(props, ctx) {
    const { page, handleSubmit } = useFormPage<IEquipeFormModel>({
      ctx,
      async submitCallback(model) {
        return UserService.updateEquipe(model);
      },
    });

    return () => (
      <ConfiguracaoClinicaPage title="Equipe">
        <EquipeForm page={page} onSubmit={handleSubmit} />
      </ConfiguracaoClinicaPage>
    );
  },
});
