import { IndexDataTable } from '@/components/datatable/IndexDataTable';
import { QueryGraphql } from '@/graphql/query';
import { useQueryConfig } from '@/lib/composables/useQueryConfig';
import { ConvenioService } from '@/lib/services';
import { createComponent } from '@/lib/vue';
import { Routes } from '@/routes/routes';
import {
  IConvenioItem,
  IConveniosQuery,
  IConveniosQueryVariables,
  IIndexDataTableActions,
  IIndexDataTableButton,
  IDataTableHeader,
} from '@/typings';
import { ConfiguracaoClinicaPage } from '../../components/ConfiguracaoClinicaPage';

export default createComponent({
  name: 'ConveniosPage',
  setup(props, ctx) {
    const btn: IIndexDataTableButton = {
      text: 'Novo Convênio',
      to: Routes.app.configClinica.convenios.new,
    };

    const actions: IIndexDataTableActions = {
      editRoute: Routes.app.configClinica.convenios.edit,
      removeMany: ConvenioService.deleteMany,
    };

    const headers: IDataTableHeader<IConvenioItem>[] = [
      {
        text: 'Nome',
        value: 'nome',
        mapValue: v => v.nome,
      },
    ];

    const { $data, $loading } = useConveniosQuery();

    return () => (
      <ConfiguracaoClinicaPage title="Convênios">
        <IndexDataTable
          btn={btn}
          headers={headers}
          items={$data.value}
          loading={$loading.value}
          actions={actions}
        />
      </ConfiguracaoClinicaPage>
    );
  },
});

function useConveniosQuery() {
  return useQueryConfig<
    IConveniosQuery,
    IConvenioItem[],
    IConveniosQueryVariables
  >({
    query: QueryGraphql.ConveniosQuery,
    variables: () => ({
      where: { particular: false },
    }),
    mapData: result =>
      result?.convenios.nodes.map(v => ({
        id: v.id,
        nome: v.nome,
        particular: v.particular,
      })) || [],
  });
}
