import { IndexDataTable } from '@/components/datatable/IndexDataTable';
import { QueryGraphql } from '@/graphql/query';
import { useQueryConfig } from '@/lib/composables/useQueryConfig';
import { CentroCustoService } from '@/lib/services';
import { createComponent } from '@/lib/vue';
import { Routes } from '@/routes/routes';
import {
  ICentroCustoItem,
  ICentrosCustosQuery,
  ICentrosCustosQueryVariables,
  IIndexDataTableActions,
  IIndexDataTableButton,
  IDataTableHeader,
} from '@/typings';
import { ConfiguracaoClinicaPage } from '../../../components/ConfiguracaoClinicaPage';

export default createComponent({
  name: 'CentrosCustosPage',
  setup(props, ctx) {
    const btn: IIndexDataTableButton = {
      text: 'Novo Centro de Custo',
      to: Routes.app.configClinica.financas.centrosCusto.new,
    };

    const actions: IIndexDataTableActions = {
      editRoute: Routes.app.configClinica.financas.centrosCusto.edit,
      removeMany: CentroCustoService.deleteMany,
    };

    const headers: IDataTableHeader<ICentroCustoItem>[] = [
      {
        text: 'Nome',
        value: 'nome',
        mapValue: v => v.nome,
      },
    ];

    const { $data, $loading } = useCentrosCustosQuery();

    return () => (
      <ConfiguracaoClinicaPage title="Centros de custo">
        <IndexDataTable
          btn={btn}
          headers={headers}
          items={$data.value}
          loading={$loading.value}
          actions={actions}
        />
      </ConfiguracaoClinicaPage>
    );
  },
});

function useCentrosCustosQuery() {
  return useQueryConfig<
    ICentrosCustosQuery,
    ICentroCustoItem[],
    ICentrosCustosQueryVariables
  >({
    query: QueryGraphql.CentrosCustosQuery,
    variables: () => ({}),
    mapData: result =>
      result?.centrosCustos.nodes.map(v => ({
        id: v.id,
        nome: v.nome,
      })) || [],
  });
}
