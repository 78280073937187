import { useFormPage } from '@/lib/composables/form/useFormPage';
import { useNome } from '@/lib/composables/utils/useNome';
import { ContaFinanceiraService } from '@/lib/services';
import { createComponent } from '@/lib/vue';
import { IContaFinanceiraModel } from '@/typings';
import { computed } from '@vue/composition-api';
import { ConfiguracaoClinicaPage } from '../../../components/ConfiguracaoClinicaPage';
import { ContaFinanceiraForm } from '../../components/ContaFinanceiraForm';

export default createComponent({
  name: 'ContaFinanceiraFormPage',
  setup(props, ctx) {
    const { page, handleSubmit, isEdit } = useFormPage<IContaFinanceiraModel>({
      ctx,
      async submitCallback(model) {
        if (page.id) {
          return ContaFinanceiraService.update(page.id, model);
        } else {
          return ContaFinanceiraService.create(model);
        }
      },
    });

    const { $nome, setNome } = useNome({ ctx });

    const $title = computed(() => {
      if ($nome.value) {
        return `Conta financeira: ${$nome.value}`;
      }

      return isEdit ? 'Editar Conta financeira' : 'Nova Conta financeira';
    });

    async function handleDelete(id: string) {
      return ContaFinanceiraService.delete(id);
    }

    return () => (
      <ConfiguracaoClinicaPage title={$title.value} form>
        <ContaFinanceiraForm
          page={page}
          onSubmit={handleSubmit}
          onDelete={handleDelete}
          onNomeChanged={setNome}
        />
      </ConfiguracaoClinicaPage>
    );
  },
});
