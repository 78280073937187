import { FormFields } from '@/components/form/fields/FormFields';
import { MyForm } from '@/components/form/MyForm';
import { PageSection } from '@/components/page/PageSection';
import {
  IFormEvents,
  IFormProps,
  useFormConfig,
} from '@/lib/composables/form/useFormConfig';
import { INomeEvents, useNome } from '@/lib/composables/utils/useNome';
import { useRoute } from '@/lib/composables/utils/useRouter';
import { ConstantsHelper } from '@/lib/constants/helper';
import { SERVER_MAX_TAKE } from '@/lib/constants/server';
import { LookupsConfigs } from '@/lib/form/lookups';
import { CategoriaFinanceiraService } from '@/lib/services';
import { createComponent, watchRun } from '@/lib/vue';
import { Routes } from '@/routes/routes';
import { redirectError } from '@/routes/utils';
import {
  ICategoriaFinanceiraModel,
  ICategoriasFinanceirasQueryVariables,
  IForm,
  IFormSchema,
  TransacaoTipo,
} from '@/typings';
import { Ref, SetupContext } from '@vue/composition-api';

interface IProps extends IFormProps {}

interface IEvents extends IFormEvents<ICategoriaFinanceiraModel>, INomeEvents {}

export const CategoriaFinanceiraForm = createComponent<IProps, IEvents>({
  name: 'CategoriaFinanceiraForm',
  props: {
    page: { type: Object, required: true },
  },
  setup(props, ctx) {
    const indexRoute =
      Routes.app.configClinica.financas.categoriasFinanceiras.index;

    const { $form, $schema, emitSubmit, emitDelete } = useForm(props, ctx);

    watchRun(() => $form.value.model.tipo, onTipoChange);

    function onTipoChange(newValue, oldValue: TransacaoTipo | null) {
      if (oldValue) {
        $form.value.model.parentId = null;
      }
    }

    useParams({ ctx, $form });

    useNome({
      ctx,
      watchNome: () => $form.value.model.nome,
    });

    return () => (
      <MyForm
        form={$form.value}
        cancelTo={indexRoute}
        onSubmit={emitSubmit}
        onDelete={emitDelete}
      >
        <PageSection>
          <FormFields
            form={$form.value}
            schema={$schema.value}
            v-model={$form.value.model}
          />
        </PageSection>
      </MyForm>
    );
  },
});

function useForm(props: IProps, ctx: SetupContext) {
  const { $form, $schema, emitSubmit, emitDelete, isEdit } = useFormConfig<
    ICategoriaFinanceiraModel,
    IFormSchema<ICategoriaFinanceiraModel>
  >({
    page: props.page,
    initialValue: {
      nome: null,
      parentId: null,
      tipo: null,
      isSubcategoria: false,
    },
    mapSchema: model => ({
      nome: {
        label: 'Nome',
        type: 'text',
        validations: {
          required: true,
        },
      },
      tipo: {
        label: 'Tipo',
        type: 'select',
        items: ConstantsHelper.transacaoTipos,
        validations: {
          required: true,
        },
      },
      isSubcategoria: {
        label: 'Subcategoria',
        type: 'checkbox',
        disabled: isEdit,
      },
      parentId: {
        label: 'Categoria mãe',
        type: 'autocomplete',
        itemLabel: 'nome',
        disabled: !model.tipo,
        hidden: !model.isSubcategoria,
        lookup: LookupsConfigs.categoriaFinanceira({
          getVariables: (): ICategoriasFinanceirasQueryVariables => ({
            where: {
              tipo: $form.value.model.tipo,
              parentId: null,
            },
            take: SERVER_MAX_TAKE,
          }),
          noCache: true,
        }),
      },
    }),
    ctx,
    async loadEditCallback({ id, setFormModel }) {
      const editValue = await CategoriaFinanceiraService.getById(id);

      if (!editValue) return redirectError(404);

      const parentId = editValue.parent?.id;

      setFormModel({
        nome: editValue.nome,
        tipo: editValue.tipo,
        parentId,
        isSubcategoria: !!parentId,
      });
    },
  });

  return { $form, $schema, emitSubmit, emitDelete, isEdit };
}

function useParams({
  ctx,
  $form,
}: {
  ctx: SetupContext;
  $form: Ref<IForm<ICategoriaFinanceiraModel>>;
}) {
  function queryParentId() {
    const parentId = useRoute().query.parentId as string;
    if (parentId) {
      $form.value.model.parentId = parentId;
      $form.value.model.isSubcategoria = true;
    }
  }

  function queryTipo() {
    const tipo = useRoute().query.tipo;

    if (tipo === TransacaoTipo.DESPESA || tipo === TransacaoTipo.RECEITA) {
      $form.value.model.tipo = tipo;
    }
  }

  queryTipo();
  queryParentId();
}
