import { FormFields } from '@/components/form/fields/FormFields';
import { MyForm } from '@/components/form/MyForm';
import { PageSection } from '@/components/page/PageSection';
import {
  IFormEvents,
  IFormProps,
  useFormConfig,
} from '@/lib/composables/form/useFormConfig';
import { ConstantsHelper } from '@/lib/constants/helper';
import { LookupsConfigs } from '@/lib/form/lookups';
import { ConfiguracaoFinanceiraService } from '@/lib/services';
import { createComponent } from '@/lib/vue';
import {
  IConfiguracaoFinanceiraFormSchema,
  IConfiguracaoFinanceiraModel,
} from '@/typings';
import { SetupContext } from '@vue/composition-api';

interface IProps extends IFormProps {}

interface IEvents extends IFormEvents<IConfiguracaoFinanceiraModel> {}

export const ConfiguracaoFinanceiraForm = createComponent<IProps, IEvents>({
  name: 'ConfiguracaoFinanceiraForm',
  props: {
    page: { type: Object, required: true },
  },
  setup(props, ctx) {
    const { $form, $schema, emitSubmit } = useForm(props, ctx);

    return () => (
      <MyForm noCancel form={$form.value} onSubmit={emitSubmit}>
        <PageSection title="Padrões">
          <FormFields
            slot="fields"
            form={$form.value}
            schema={$schema.value.padroes}
            v-model={$form.value.model.padroes}
          />
        </PageSection>

        <PageSection
          title="Recebimentos"
          text="Recebimentos de atendimentos realizados"
        >
          <FormFields
            slot="fields"
            form={$form.value}
            schema={$schema.value.recebimentosAtendimento}
            v-model={$form.value.model.recebimentosAtendimento}
          />
        </PageSection>
      </MyForm>
    );
  },
});
function useForm(props: IProps, ctx: SetupContext) {
  return useFormConfig<
    IConfiguracaoFinanceiraModel,
    IConfiguracaoFinanceiraFormSchema
  >({
    page: props.page,
    initialValue: {
      padroes: {
        contaFinanceiraId: null,
        centroCustoId: null,
        formaPagamento: null,
      },
      recebimentosAtendimento: {
        recebimentoLancamento: true,
        lancarAPartir: null,
      },
    },
    mapSchema: model => ({
      padroes: {
        contaFinanceiraId: {
          label: 'Conta',
          type: 'autocomplete',
          itemLabel: 'nome',
          lookup: LookupsConfigs.contaFinanceira(),
          validations: { required: true },
        },
        centroCustoId: {
          label: 'Centro de custo',
          type: 'autocomplete',
          itemLabel: 'nome',
          lookup: LookupsConfigs.centroCusto(),
          layout: { sm: 6 },
        },
        formaPagamento: {
          label: 'Forma de pagamento',
          type: 'select',
          items: ConstantsHelper.formasPagamento,
          validations: { required: true },
          layout: { sm: 6 },
        },
      },
      recebimentosAtendimento: {
        recebimentoLancamento: {
          label: 'Habilitar lançamento no financeiro',
          type: 'switch',
          layout: { maxWidth: 320 },
        },
        lancarAPartir: {
          label: 'Lançar a partir de',
          type: 'select',
          items: ConstantsHelper.configuracaoFinanceiraRecebimentoLancarAPartir,
          hidden: !model.recebimentosAtendimento.recebimentoLancamento,
          layout: { maxWidth: 200 },
        },
      },
    }),
    ctx,
    noId: true,
    async loadEditCallback({ setFormModel }) {
      const editValue = await ConfiguracaoFinanceiraService.get();

      if (editValue) {
        setFormModel({
          padroes: {
            contaFinanceiraId: editValue.contaFinanceira?.id,
            centroCustoId: editValue.centroCusto?.id,
            formaPagamento: editValue.formaPagamento,
          },
          recebimentosAtendimento: {
            recebimentoLancamento: editValue.recebimentoLancamento,
            lancarAPartir: editValue.recebimentoLancarAPartir,
          },
        });
      }
    },
  });
}
