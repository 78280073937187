import { useFormPage } from '@/lib/composables/form/useFormPage';
import { useAuthInfo } from '@/lib/composables/useAuthInfo';
import { useNome } from '@/lib/composables/utils/useNome';
import { ClinicaService } from '@/lib/services';
import { createComponent } from '@/lib/vue';
import { IClinicaFormModel } from '@/typings';
import { computed } from '@vue/composition-api';
import { ClinicaForm } from '../../account/components/forms/ClinicaForm';
import { ConfiguracaoClinicaPage } from '../components/ConfiguracaoClinicaPage';

export default createComponent({
  name: 'ClinicaPerfilPage',
  setup(props, ctx) {
    const { $clinica } = useAuthInfo();

    const { page, handleSubmit } = useFormPage<IClinicaFormModel>({
      ctx,
      id: $clinica.value?.id,
      submitCallback(model) {
        return ClinicaService.perfilUpdate(model);
      },
    });

    const { $nome, setNome } = useNome({ ctx });

    const $title = computed(() =>
      $nome.value ? `Clínica: ${$nome.value}` : 'Perfil da clínica',
    );

    return () => (
      <ConfiguracaoClinicaPage title={$title.value} form>
        <ClinicaForm
          perfil
          page={page}
          onSubmit={handleSubmit}
          onNomeChanged={setNome}
        />
      </ConfiguracaoClinicaPage>
    );
  },
});
