import { MyPage } from '@/components/page/MyPage';
import { useState } from '@/lib/composables';
import { createComponent } from '@/lib/vue';
import { userHasClinicas } from '@/store/utils/auth';
import { computed } from '@vue/composition-api';

export const ConfiguracaoClinicaPage = createComponent({
  name: 'ConfiguracaoClinicaPage',
  props: {
    title: { type: String, required: true },
    form: { type: Boolean, default: false },
  },
  setup(props, ctx) {
    const $clinica = useState(s => s.auth.clinica?.nome);

    const $showCard = computed(() => userHasClinicas());

    return () => {
      const defaultSlot = ctx.slots.default?.();

      return (
        <div class="flex flex-col">
          {$showCard.value && (
            <v-card outlined class="p-4 mb-4">
              <div class="text-lg">
                <span class="font-normal text-gray-700">
                  Você está alterando as configurações da clínica:
                </span>

                <span class="ml-1 font-medium text-primary">
                  {$clinica.value}
                </span>
              </div>
            </v-card>
          )}

          <MyPage title={props.title} form={props.form}>
            {defaultSlot}
          </MyPage>
        </div>
      );
    };
  },
});
