import { FormFields } from '@/components/form/fields/FormFields';
import { MyForm } from '@/components/form/MyForm';
import { PageSection } from '@/components/page/PageSection';
import { FormHeader } from '@/components/typography/FormHeader';
import {
  IDialogFormProps,
  IFormEvents,
  useDialogFormConfig,
} from '@/lib/composables/form/useFormConfig';
import { LookupsConfigs } from '@/lib/form/lookups';
import { createComponent } from '@/lib/vue';
import {
  IConvenioPlanoModel,
  IConvenioProfissionalModel,
  IFormSchema,
  IProfissional,
} from '@/typings';
import { computed, SetupContext } from '@vue/composition-api';
import { ConvenioProfissionalPlanos } from './ConvenioProfissionalPlanos';

interface IProps extends IDialogFormProps<IConvenioProfissionalModel> {
  planos: IConvenioPlanoModel[];
  profissionaisIds: string[];
}

interface IEvents extends IFormEvents<IConvenioProfissionalModel> {}

export const ConvenioProfissionalForm = createComponent<IProps, IEvents>({
  name: 'ConvenioProfissionalForm',
  props: {
    page: { type: Object, required: true },
    initialValue: { type: Object },
    planos: { type: Array, required: true },
    profissionaisIds: { type: Array, required: true },
  },
  setup(props, ctx) {
    const { $form, $schema, emitSubmit, emitCancel } = useProfissionalForm(
      props,
      ctx,
    );

    return () => (
      <MyForm
        dialog
        form={$form.value}
        onSubmit={emitSubmit}
        onCancel={emitCancel}
      >
        <PageSection>
          <FormHeader title="Dados do profissional" />

          <FormFields
            form={$form.value}
            schema={$schema.value}
            v-model={$form.value.model}
          />
        </PageSection>

        <ConvenioProfissionalPlanos
          planos={props.planos}
          v-model={$form.value.model.planos}
        />
      </MyForm>
    );
  },
});

function useProfissionalForm(props: IProps, ctx: SetupContext) {
  const defaultValue: IConvenioProfissionalModel = {
    profissionalId: null,
    nome: null,
    codigoOperadora: null,
    planos: [],
  };

  return useDialogFormConfig<
    IConvenioProfissionalModel,
    IFormSchema<IConvenioProfissionalModel>
  >({
    page: props.page,
    ctx,
    initialValue: props.initialValue || defaultValue,
    mapSchema: (model, $form) => {
      const $profissional = computed<IProfissional | null>(() => {
        if (model.profissionalId) {
          return {
            id: model.profissionalId,
            nome: model.nome!,
          };
        }
        return null;
      });
      return {
        profissionalId: {
          label: 'Profissional',
          type: 'autocomplete',
          itemLabel: 'nome',
          editValue: $profissional.value,
          lookup: LookupsConfigs.user.profissionais({
            onModelChange(value) {
              $form.value.model.nome = value ? value.nome : null;
            },
            mapData(data) {
              return data.users.nodes.filter(
                f => !props.profissionaisIds.includes(f.id),
              );
            },
          }),
          validations: {
            required: true,
          },
        },
        codigoOperadora: {
          label: 'Código na operadora',
          type: 'text',
          validations: {
            required: true,
          },
        },
      };
    },
  });
}
