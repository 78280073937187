import { MyForm } from '@/components/form/MyForm';
import {
  IFormEvents,
  IFormProps,
  useFormConfig,
} from '@/lib/composables/form/useFormConfig';
import { createComponent } from '@/lib/vue';
import { IEquipeFormModel } from '@/typings';
import { EquipeProfissionais } from './EquipeProfissionais';

interface IProps extends IFormProps {}

interface IEvents extends IFormEvents<IEquipeFormModel> {}

export const EquipeForm = createComponent<IProps, IEvents>({
  name: 'EquipeForm',
  props: {
    page: { type: Object, required: true },
  },
  setup(props, ctx) {
    const { $form, emitSubmit } = useFormConfig<IEquipeFormModel, any>({
      page: props.page,
      initialValue: {
        profissionais: [],
        recepcionistas: [],
      },
      mapSchema: () => ({}),
      ctx,
    });

    return () => (
      <MyForm noCancel form={$form.value} onSubmit={emitSubmit}>
        <EquipeProfissionais v-model={$form.value.model.profissionais} />

        <EquipeProfissionais
          recepcionista
          v-model={$form.value.model.recepcionistas}
        />
      </MyForm>
    );
  },
});
