import { TdInputNumber } from '@/components/datatable/columns/TdInputNumber';
import { TdSwitch } from '@/components/datatable/columns/TdSwitch';
import { DataTable } from '@/components/datatable/DataTable';
import { PageSection } from '@/components/page/PageSection';
import { useValue } from '@/lib/composables';
import { createComponent, watchRun } from '@/lib/vue';
import {
  IConvenioPlanoModel,
  IConvenioPlanoProfissionalModel,
  IConvenioProfissionalPlanoItem,
  IFormInputNumber,
  IFormInputSwitch,
  IRequireField,
  IDataTableHeader,
} from '@/typings';
import { Ref, SetupContext } from '@vue/composition-api';

interface IJsxProps {
  // model
  value?: IConvenioPlanoProfissionalModel[];
  planos: IConvenioPlanoModel[] | null | undefined;
}

type IProps = IRequireField<IJsxProps, 'value'>;

interface IEvents {
  onInput: (value: IConvenioPlanoProfissionalModel[]) => void;
}

export const ConvenioProfissionalPlanos = createComponent<IJsxProps, IEvents>({
  name: 'ConvenioProfissionalPlanos',
  props: {
    value: { type: Array, required: true },
    planos: { type: Array, default: () => [] },
  },
  setup(props: IProps, ctx) {
    const [$items, setItems] = useValue<IConvenioProfissionalPlanoItem[]>([]);

    const headers = useHeaders();

    useWatchers({ props, ctx, $items, setItems });

    return () => (
      <PageSection>
        <DataTable
          title="Planos atendidos"
          headers={headers}
          items={$items.value}
        />
      </PageSection>
    );
  },
});

function useHeaders(): IDataTableHeader<IConvenioProfissionalPlanoItem>[] {
  return [
    {
      text: 'Atende',
      value: 'atende',
      slot: ({ header, item }) => [
        <TdSwitch
          header={header}
          input={getSchema(item).atende}
          value={item.atende}
          onInput={v => (item.atende = !!v)}
        />,
      ],
      align: 'center',
      sortable: false,
      width: 60,
    },
    {
      text: 'Plano',
      value: 'plano',
      mapValue: v => v.planoNome,
      align: 'start',
      valueAlign: 'start',
    },
    {
      text: 'Perído de carência (em dias)',
      value: 'periodoCarenciaDias',
      slot: ({ header, item }) => [
        <TdInputNumber
          class="mx-auto center-input dense-input"
          header={header}
          width={100}
          input={getSchema(item).periodoCarenciaDias}
          value={item.periodoCarenciaDias}
          onInput={v => (item.periodoCarenciaDias = v || 0)}
        />,
      ],
      sortable: false,
      width: 200,
    },
  ];
}

function getSchema(item: IConvenioProfissionalPlanoItem): {
  atende: IFormInputSwitch;
  periodoCarenciaDias: IFormInputNumber;
} {
  return {
    atende: {
      label: null,
      type: 'switch',
    },
    periodoCarenciaDias: {
      label: null,
      type: 'number',
      integer: true,
      hideDetails: true,
      disabled: !item.atende,
    },
  };
}

function useWatchers({
  props,
  ctx,
  $items,
  setItems,
}: {
  props: IProps;
  ctx: SetupContext;
  $items: Ref<IConvenioProfissionalPlanoItem[]>;
  setItems: (v: IConvenioProfissionalPlanoItem[]) => void;
}) {
  function emitInput(model: IConvenioPlanoProfissionalModel[]) {
    ctx.emit('input', model);
  }

  function initializeItems() {
    setItems(
      (props.planos || []).map(plano => {
        const model = props.value.find(f => f.planoId === plano.id);

        return {
          planoId: plano.id!,
          planoNome: plano.nome!,
          periodoCarenciaDias: model?.periodoCarenciaDias || 30,
          atende: !!model,
        };
      }),
    );
  }

  watchRun(
    $items,
    items => {
      const value = items
        .filter(v => v.atende)
        .map(({ planoId, periodoCarenciaDias }) => ({
          planoId,
          periodoCarenciaDias,
        }));

      emitInput(value);
    },
    { deep: true },
  );

  initializeItems();
}
