import { IndexDataTable } from '@/components/datatable/IndexDataTable';
import { useFormPage } from '@/lib/composables/form/useFormPage';
import { getEditValue } from '@/lib/form';
import { DialogHelpers } from '@/lib/helpers/dialogs/dialog.helpers';
import { uuid } from '@/lib/helpers/uuid';
import { createComponent } from '@/lib/vue';
import {
  IConvenioPlanoModel,
  IConvenioProfissionalModel,
  IFormPage,
  IIndexDataTableActions,
  IIndexDataTableButton,
  IRequireField,
  IDataTableHeader,
} from '@/typings';
import { computed, ComputedRef } from '@vue/composition-api';
import { ConvenioProfissionalForm } from './ConvenioProfissionalForm';

interface IJsxProps {
  // model
  value?: IConvenioProfissionalModel[];
  planos: IConvenioPlanoModel[];
}

type IProps = IRequireField<IJsxProps, 'value'>;

interface IEvents {
  onInput: (items: IConvenioProfissionalModel[]) => void;
}

export const ConvenioProfissionais = createComponent<IJsxProps, IEvents>({
  name: 'ConvenioProfissionais',
  props: {
    value: { type: Array, required: true },
    planos: { type: Array, required: true },
  },
  setup(props: IProps, ctx) {
    function emitInput(items: IConvenioProfissionalModel[]) {
      ctx.emit('input', items);
    }
    const $profissionaisIds = computed<string[]>(() =>
      props.value.filter(f => f.profissionalId).map(v => v.profissionalId!),
    );

    const headers: IDataTableHeader<IConvenioProfissionalModel>[] = [
      {
        text: 'Nome',
        value: 'nome',
        mapValue: v => v.nome,
      },
    ];

    const { page, handleSubmit } = useFormPage({
      ctx,
      async submitCallback(dialogModel: IConvenioProfissionalModel) {
        if (!dialogModel.id) {
          dialogModel.id = uuid();
          dialogModel.new = true;

          emitInput([...props.value, dialogModel]);
        } else {
          emitInput([
            ...props.value.filter(v => v.id !== dialogModel.id),
            dialogModel,
          ]);
        }
      },
    });

    const { btn, actions } = useActions({
      props,
      $profissionaisIds,
      page,
      handleSubmit,
      emitInput,
    });

    return () => (
      <IndexDataTable
        selectable
        title="Profissionais"
        btn={btn}
        headers={headers}
        items={props.value}
        actions={actions}
      />
    );
  },
});

function useActions({
  props,
  $profissionaisIds,
  page,
  handleSubmit,
  emitInput,
}: {
  props: IProps;
  $profissionaisIds: ComputedRef<string[]>;
  page: IFormPage;
  handleSubmit: (model: IConvenioProfissionalModel) => Promise<void>;
  emitInput: (items: IConvenioProfissionalModel[]) => void;
}) {
  function handleNew() {
    return showDialog({
      title: 'Novo profissional',
      initialValue: null,
    });
  }

  function handleEdit(id?: string | null) {
    const initialValue = getEditValue(id, props.value);

    if (!initialValue) return handleNew();

    return showDialog({
      title: 'Editar profissional',
      initialValue,
    });
  }

  function showDialog({
    title,
    initialValue,
  }: {
    title: string;
    initialValue: IConvenioProfissionalModel | null;
  }) {
    return DialogHelpers.system.form({
      title,
      form: ConvenioProfissionalForm,
      page,
      initialValue,
      onSubmit: handleSubmit,
      planos: props.planos,
      profissionaisIds: $profissionaisIds.value,
    });
  }

  function handleRemove(id: string) {
    emitInput(props.value.filter(f => f.id !== id));
  }

  const actions: IIndexDataTableActions = {
    edit: handleEdit,
    remove: handleRemove,
  };

  const btn: IIndexDataTableButton = {
    text: 'Novo profissional',
    outlined: true,
    onClick: handleNew,
  };

  return { actions, btn };
}
