import { IndexDataTable } from '@/components/datatable/IndexDataTable';
import { QueryGraphql } from '@/graphql/query';
import { useQueryConfig } from '@/lib/composables/useQueryConfig';
import { ConstantsHelper } from '@/lib/constants/helper';
import { ContaFinanceiraService } from '@/lib/services';
import { createComponent } from '@/lib/vue';
import { Routes } from '@/routes/routes';
import {
  IContaFinanceiraFragment,
  IContaFinanceiraItem,
  IContasFinanceirasQuery,
  IContasFinanceirasQueryVariables,
  IDataTableHeader,
  IIndexDataTableActions,
  IIndexDataTableButton,
} from '@/typings';
import { ConfiguracaoClinicaPage } from '../../../components/ConfiguracaoClinicaPage';

export default createComponent({
  name: 'ContasFinanceirasPage',
  setup(props, ctx) {
    const btn: IIndexDataTableButton = {
      text: 'Nova Conta financeira',
      to: Routes.app.configClinica.financas.contasFinanceiras.new,
    };

    const actions: IIndexDataTableActions = {
      editRoute: Routes.app.configClinica.financas.contasFinanceiras.edit,
      removeMany: ContaFinanceiraService.deleteMany,
    };

    const headers: IDataTableHeader<IContaFinanceiraFragment>[] = [
      {
        text: 'Nome',
        value: 'nome',
        mapValue: v => v.nome,
      },
      {
        text: 'Tipo',
        value: 'tipo',
        mapValue: v => v.tipo,
        slot: v => {
          const item = ConstantsHelper.contaFinanceiraTipos.find(
            f => f.value === v.item.tipo,
          );

          if (!item) return null;

          return (
            <div class="flex flex-auto items-center space-x-3">
              <v-icon>{item.icon}</v-icon>

              <div>{item.label}</div>
            </div>
          );
        },
      },
    ];

    const { $data, $loading } = useContasFinanceirasQuery();

    return () => (
      <ConfiguracaoClinicaPage title="Contas financeiras">
        <IndexDataTable
          btn={btn}
          headers={headers}
          items={$data.value}
          loading={$loading.value}
          actions={actions}
        />
      </ConfiguracaoClinicaPage>
    );
  },
});

function useContasFinanceirasQuery() {
  return useQueryConfig<
    IContasFinanceirasQuery,
    IContaFinanceiraItem[],
    IContasFinanceirasQueryVariables
  >({
    query: QueryGraphql.ContasFinanceirasQuery,
    variables: () => ({}),
    mapData: result => result?.contasFinanceiras.nodes || [],
  });
}
