import { useFormPage } from '@/lib/composables/form/useFormPage';
import { useNome } from '@/lib/composables/utils/useNome';
import { CategoriaFinanceiraService } from '@/lib/services';
import { createComponent } from '@/lib/vue';
import { ICategoriaFinanceiraModel } from '@/typings';
import { computed } from '@vue/composition-api';
import { ConfiguracaoClinicaPage } from '../../../components/ConfiguracaoClinicaPage';
import { CategoriaFinanceiraForm } from '../../components/CategoriaFinanceiraForm';

export default createComponent({
  name: 'CategoriaFinanceiraFormPage',
  setup(props, ctx) {
    const { page, handleSubmit, isEdit } =
      useFormPage<ICategoriaFinanceiraModel>({
        ctx,
        async submitCallback(model) {
          if (page.id) {
            return CategoriaFinanceiraService.update(page.id, model);
          } else {
            return CategoriaFinanceiraService.create(model);
          }
        },
      });

    const { $nome, setNome } = useNome({ ctx });

    const $title = computed(() => {
      if ($nome.value) {
        return `Categoria financeira: ${$nome.value}`;
      }

      return isEdit
        ? 'Editar Categoria financeira'
        : 'Nova Categoria financeira';
    });

    async function handleDelete(id: string) {
      return CategoriaFinanceiraService.delete(id);
    }

    return () => (
      <ConfiguracaoClinicaPage title={$title.value} form>
        <CategoriaFinanceiraForm
          page={page}
          onSubmit={handleSubmit}
          onDelete={handleDelete}
          onNomeChanged={setNome}
        />
      </ConfiguracaoClinicaPage>
    );
  },
});
