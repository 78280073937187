import { IndexDataTable } from '@/components/datatable/IndexDataTable';
import { useFormPage } from '@/lib/composables/form/useFormPage';
import { getEditValue } from '@/lib/form';
import { DialogHelpers } from '@/lib/helpers/dialogs/dialog.helpers';
import { uuid } from '@/lib/helpers/uuid';
import { createComponent } from '@/lib/vue';
import {
  IConvenioPlanoModel,
  IFormPage,
  IIndexDataTableActions,
  IIndexDataTableButton,
  IRequireField,
  IDataTableHeader,
} from '@/typings';
import { SetupContext } from '@vue/composition-api';
import { ConvenioPlanoForm } from './ConvenioPlanoForm';

interface IJsxProps {
  // model
  value?: IConvenioPlanoModel[];
}

type IProps = IRequireField<IJsxProps, 'value'>;

export const ConvenioPlanos = createComponent<IJsxProps>({
  name: 'ConvenioPlanos',
  props: {
    value: { type: Array, required: true },
  },
  setup(props: IProps, ctx) {
    function emitInput(value: IConvenioPlanoModel[]) {
      ctx.emit('input', value);
    }

    const { handleSubmit, headers, page } = useData({ props, ctx, emitInput });

    const { btn, actions } = useActions({
      props,
      page,
      emitInput,
      handleSubmit,
    });

    return () => (
      <IndexDataTable
        selectable
        title="Planos"
        btn={btn}
        headers={headers}
        items={props.value}
        actions={actions}
      />
    );
  },
});

function useData({
  props,
  ctx,
  emitInput,
}: {
  props: IProps;
  ctx: SetupContext;
  emitInput: (value: IConvenioPlanoModel[]) => void;
}) {
  const { page, handleSubmit } = useFormPage<IConvenioPlanoModel>({
    ctx,
    async submitCallback(dialogModel) {
      if (!dialogModel.id) {
        dialogModel.id = uuid();
        dialogModel.new = true;

        emitInput([...props.value, dialogModel]);
      } else {
        emitInput([
          ...props.value.filter(v => v.id !== dialogModel.id),
          dialogModel,
        ]);
      }
    },
  });

  const headers: IDataTableHeader<IConvenioPlanoModel>[] = [
    {
      text: 'Nome',
      value: 'nome',
      mapValue: v => v.nome,
    },
  ];

  return { page, handleSubmit, headers };
}

function useActions({
  props,
  page,
  emitInput,
  handleSubmit,
}: {
  props: IProps;
  page: IFormPage;
  emitInput: (value: IConvenioPlanoModel[]) => void;
  handleSubmit: (model: IConvenioPlanoModel) => Promise<void>;
}) {
  function handleEdit(id?: string | null) {
    const initialValue = getEditValue(id, props.value);

    if (!initialValue) return handleNew();

    return showDialog({
      title: 'Editar plano',
      initialValue,
    });
  }

  function handleRemove(id: string) {
    emitInput(props.value.filter(f => f.id !== id));
  }

  function handleNew() {
    return showDialog({
      title: 'Novo plano',
      initialValue: null,
    });
  }

  function showDialog({
    title,
    initialValue,
  }: {
    title: string;
    initialValue: IConvenioPlanoModel | null;
  }) {
    return DialogHelpers.system.form({
      title,
      form: ConvenioPlanoForm,
      page,
      initialValue,
      onSubmit: handleSubmit,
    });
  }

  const actions: IIndexDataTableActions = {
    edit: handleEdit,
    remove: handleRemove,
  };

  const btn: IIndexDataTableButton = {
    text: 'Novo Plano',
    outlined: true,
    onClick: handleNew,
  };

  return { actions, btn };
}
