import { FormFields } from '@/components/form/fields/FormFields';
import { MyForm } from '@/components/form/MyForm';
import { PageSection } from '@/components/page/PageSection';
import { Tabs } from '@/components/utils/Tabs';
import { useValue } from '@/lib/composables';
import {
  IFormEvents,
  IFormProps,
  useFormConfig,
} from '@/lib/composables/form/useFormConfig';
import { INomeEvents, useNome } from '@/lib/composables/utils/useNome';
import { LookupsConfigs } from '@/lib/form/lookups';
import { MyIcons } from '@/lib/helpers/MyIcons';
import { ConvenioService } from '@/lib/services';
import { createComponent } from '@/lib/vue';
import { Routes } from '@/routes/routes';
import { redirectError } from '@/routes/utils';
import {
  IConvenioFormModel,
  IConvenioLookupFragment,
  IConvenioModel,
  IConvenioPlanoModel,
  IConvenioProfissionalModel,
  IFormSchema,
  ITab,
} from '@/typings';
import { SetupContext } from '@vue/composition-api';
import flatten from 'lodash/flatten';
import isString from 'lodash/isString';
import { ConvenioPlanos } from './ConvenioPlanos';
import { ConvenioProfissionais } from './ConvenioProfissionais';

interface IProps extends IFormProps {}

interface IEvents extends IFormEvents<IConvenioFormModel>, INomeEvents {}

export const ConvenioForm = createComponent<IProps, IEvents>({
  name: 'ConvenioForm',
  props: {
    page: { type: Object, required: true },
  },
  setup(props, ctx) {
    const { $activeTab, indexRoute, tabs } = useData();

    const { $form, $schema, emitSubmit, emitDelete } = useForm(props, ctx);

    useNome({
      ctx,
      watchNome: () => $form.value.model.convenio.nome,
    });

    return () => (
      <MyForm
        form={$form.value}
        cancelTo={indexRoute}
        onSubmit={emitSubmit}
        onDelete={emitDelete}
      >
        <PageSection title="Convênio">
          <FormFields
            slot="fields"
            form={$form.value}
            schema={$schema.value}
            v-model={$form.value.model.convenio}
          />
        </PageSection>

        <PageSection divider>
          <Tabs items={tabs} v-model={$activeTab.value}>
            <ConvenioPlanos slot="planos" v-model={$form.value.model.planos} />

            <ConvenioProfissionais
              slot="profissionais"
              planos={$form.value.model.planos}
              v-model={$form.value.model.profissionais}
            />
          </Tabs>
        </PageSection>
      </MyForm>
    );
  },
});

function useData() {
  const indexRoute = Routes.app.configClinica.convenios.index;

  const [$activeTab] = useValue(0);

  const tabs: ITab[] = [
    {
      id: 'planos',
      text: 'planos',
      icon: MyIcons.convenioPlanos,
    },
    {
      id: 'profissionais',
      text: 'profissionais',
      icon: MyIcons.users,
    },
  ];

  return { indexRoute, $activeTab, tabs };
}

function useForm(props: IProps, ctx: SetupContext) {
  return useFormConfig<IConvenioFormModel, IFormSchema<IConvenioModel>>({
    page: props.page,
    initialValue: {
      convenio: {
        convenio: null,
        nome: null,
        registroAns: null,
        periodoCarenciaDias: null,
      },
      planos: [],
      profissionais: [],
    },
    mapSchema: (model, $form) => ({
      convenio: {
        label: 'Nome',
        type: 'combobox',
        itemLabel: 'nomeFantasia',
        lookup: LookupsConfigs.convenioLookups({
          onModelChange(value: string | IConvenioLookupFragment | null) {
            if (!value || isString(value)) {
              $form.value.model.convenio.nome = value;
              return;
            }

            $form.value.model.convenio.nome = value.nomeFantasia;
            $form.value.model.convenio.registroAns = value.registroAns;
            $form.value.model.convenio.periodoCarenciaDias =
              $form.value.model.convenio.periodoCarenciaDias || 30;
          },
        }),
        validations: {
          required: true,
        },
      },
      registroAns: {
        label: 'Registro ANS',
        type: 'text',
        mask: 'numbers6',
        validations: { required: true },
        layout: { maxWidth: 200 },
      },
      periodoCarenciaDias: {
        label: 'Período de carência',
        type: 'number',
        suffix: 'dias',
        integer: true,
        validations: {
          required: true,
        },
        layout: {
          maxWidth: 240,
        },
      },
    }),
    ctx,
    async loadEditCallback({ id, setFormModel }) {
      const editValue = await ConvenioService.getById(id);

      if (!editValue) return redirectError(404);

      const planos: IConvenioPlanoModel[] = (editValue.planos || []).map(v => ({
        id: v.id,
        nome: v.nome,
      }));

      const profissionais: IConvenioProfissionalModel[] = (
        editValue.profissionais || []
      ).map(v => ({
        id: v.id,
        profissionalId: v.profissional.id,
        nome: v.profissional.nome,
        codigoOperadora: v.codigoOperadora,
        planos: flatten(
          (editValue.planos || []).map(plano =>
            (plano.profissionais || [])
              .filter(f => f.profissional.id === v.profissional.id)
              .map(p => ({
                planoId: plano.id,
                periodoCarenciaDias: p.periodoCarenciaDias,
              })),
          ),
        ),
      }));

      setFormModel({
        convenio: {
          convenio: {
            nomeFantasia: editValue.nome,
            registroAns: editValue.registroAns,
          },
          nome: editValue.nome,
          registroAns: editValue.registroAns,
          periodoCarenciaDias: editValue.periodoCarenciaDias,
        },
        planos,
        profissionais,
      });
    },
    mapValueToValidate: value => value.convenio,
  });
}
