import { FormFields } from '@/components/form/fields/FormFields';
import { MyForm } from '@/components/form/MyForm';
import { PageSection } from '@/components/page/PageSection';
import {
  IFormEvents,
  IFormProps,
  useFormConfig,
} from '@/lib/composables/form/useFormConfig';
import { INomeEvents, useNome } from '@/lib/composables/utils/useNome';
import { CentroCustoService } from '@/lib/services';
import { createComponent } from '@/lib/vue';
import { Routes } from '@/routes/routes';
import { redirectError } from '@/routes/utils';
import { ICentroCustoModel, IFormSchema } from '@/typings';
import { SetupContext } from '@vue/composition-api';

interface IProps extends IFormProps {}

interface IEvents extends IFormEvents<ICentroCustoModel>, INomeEvents {}

export const CentroCustoForm = createComponent<IProps, IEvents>({
  name: 'CentroCustoForm',
  props: {
    page: { type: Object, required: true },
  },
  setup(props, ctx) {
    const indexRoute = Routes.app.configClinica.financas.centrosCusto.index;

    const { $form, $schema, emitSubmit, emitDelete } = useForm(props, ctx);

    useNome({ ctx, watchNome: () => $form.value.model.nome });

    return () => (
      <MyForm
        form={$form.value}
        cancelTo={indexRoute}
        onSubmit={emitSubmit}
        onDelete={emitDelete}
      >
        <PageSection>
          <FormFields
            form={$form.value}
            schema={$schema.value}
            v-model={$form.value.model}
          />
        </PageSection>
      </MyForm>
    );
  },
});

function useForm(props: IProps, ctx: SetupContext) {
  return useFormConfig<ICentroCustoModel, IFormSchema<ICentroCustoModel>>({
    ctx,
    page: props.page,
    initialValue: { nome: null },
    mapSchema: model => {
      const dis = model.nome === 'dis';
      return {
        nome: {
          label: 'Nome',
          type: 'text',
          disabled: dis,
          validations: {
            required: true,
          },
        },
      };
    },
    async loadEditCallback({ id, setFormModel }) {
      const editValue = await CentroCustoService.getById(id);

      if (!editValue) return redirectError(404);

      setFormModel({ nome: editValue.nome });
    },
  });
}
